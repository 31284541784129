<script>
import partnerChoices from '@/mixins/partnerChoices';
import {hasEditPermission,currentRoleLevelName,trialActiveForClients} from '@/mixins/roleLevelPermissions';

import {
	pureFilters,
	getPartnerName,
	getOtherPartnerName,
} from '@/lib/agreement/filters';

export const newCorporation = () => ({
	shares: [],
	address: {},
	exclusions: [],
	additionalClauses: [],
	additionalClausesToRecitals:[]
});

export default {
	mixins: [partnerChoices],
	props: ['agreementData', 'value', 'testid','hideForEdit'],
	data() {
		return {
			isViewMode : !hasEditPermission('section-property') 
		};
	},
	computed: {
		corporation() {
			return this.value;
		},
		uniqueShareholders() {
			return pureFilters.uniqueShareholders(this.corporation);
		},
		jointOwnership() {
			return this.uniqueShareholders.length > 1;
		},
		shareRollover() {
			return (
				this.corporation.shares &&
				this.corporation.shares.some(({ plan }) => plan === 'rollover')
			);
		},
		uniqueShareholderName() {
			return this.uniqueShareholders.length === 1
				? getPartnerName(
						this.corporation.shares[0].shareholder,
						this.agreementData
				  )
				: '';
		},
		nonShareholderName() {
			return this.shareRollover
				? getOtherPartnerName(
						this.corporation.shares.find(
							({ plan }) => plan === 'rollover'
						).shareholder,
						this.agreementData
				  )
				: '';
		},
		clientUserAgreement(){
			return trialActiveForClients() || currentRoleLevelName === 'client-sharing-user';
		}
	},
};
</script>

<template>
	<div>
		<input-group
			v-model="corporation.name"
			class="mb-2"
			input-cols="8"
			label="Name of Corporation"
			reset-undefined
			:testid="'corporation-name-'+testid"
			:disabled="isViewMode"
		/>

		<input-group
			v-model="corporation.number"
			class="my-3"
			reset-undefined
			input-cols="4"
			label="Incorporation Number"
			:testid="'corporation-number-'+testid"
			:disabled="isViewMode"
		/>

		<address-input-group v-model="corporation.address" class="my-3" :testid="'corporation-address-'+testid" :disabled="isViewMode"/>
		<template v-if="!clientUserAgreement">
		<input-group
			v-model="corporation.percentage"
			reset-undefined
			class="my-3"
			input-cols="2"
			percentage
			:label="`What percent of ${corporation.name || 'The Corporation'} is owned by the parties?`"
			:testid="'corporation-percentage-'+testid"
			:disabled="isViewMode"
		/></template>

		<input-group
			v-model="corporation.valuation"
			reset-undefined
			class="my-3"
			dollar
			label="What is the value of all of the shares considered to be family property?"
			:testid="'corporation-valuation-'+testid"
			:disabled="isViewMode"
		/>
		<template v-if="!clientUserAgreement">
			<yes-no-choice
				v-model="corporation.professionalValuation"
				reset-undefined
				label="Did this value come from a professional valuation?"
				class="my-2"
				:testid="'corporation-professional-valuation-'+testid"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="my-3"
				:condition="corporation.professionalValuation"
			>
				<date-input
					v-model="corporation.valuationDate"
					reset-undefined
					label="Date of valuation"
					:testid="'corporation-valuation-date-'+testid"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<yes-no-choice
				v-model="corporation.hasExclusions"
				reset-undefined
				label="Are there any exclusions related to the Corporation?"
				class="my-3"
				:testid="'corporation-has-exclusions-'+testid"
				:disabled="isViewMode"
			/>

			<conditional-group class="my-3" :condition="corporation.hasExclusions">
				<exclusion-table
					v-model="corporation.exclusions"
					:agreement-data="agreementData"
					:testid="'corporation-exclusion-'+testid+'-'"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<p class="mt-3 mb-1">Division of Shares</p>
				<corporation-division-table
					v-model="corporation.shares"
					:agreement-data="agreementData"
					:testid="'corporation-division-'+testid+'-'"
					:hideForEdit="hideForEdit"
					:disabled="isViewMode"
				/>
			<!-- <table-input
				v-model="corporation.shares"
				:fields="[
					{ key: 'shareholder', heading: 'Shareholder' },
					{
						key: 'proportion',
						heading: 'Proportion of Family Owned Shares',
					},
					{ key: 'plan', heading: 'Plan for these shares' },
				]"
				:max-rows="2"
				:testid="'corporation-exclusion-'+testid+'-'"
			>
				<template #shareholder="{ value, input, index}">
					<b-form-select
						size="sm"
						:options="partnerChoices"
						:value="value"
						@input="input"
						:data-testid="'corporation-'+testid+'-partner-shares-'+index"
					/>
				</template>

				<template #plan="{ value, input, index }">
					<b-form-select
						size="sm"
						:options="[
							{ text: 'Keep their shares', value: 'keep' },
							{
								text: 'Transfer their shares to the other party',
								value: 'transfer',
							},
							{
								text: 'Rollover their shares to a new company',
								value: 'rollover',
							},
						]"
						:value="value"
						@input="input"
						:data-testid="'corporation-'+testid+'-plans-for-shares-'+index"
					/>
				</template>

				<template #proportion="{ value, input, index }">
					<b-input-group size="sm" append="%">
						<b-form-input
							size="sm"
							type="number"
							:value="value"
							@change="input"
							:data-testid="'corporation-'+testid+'-proportion-'+index"
						/>
					</b-input-group>
				</template>
			</table-input> -->

			<conditional-group class="my-3" :condition="shareRollover">
				<input-group
					v-model="corporation.rolloverAmount"
					reset-undefined
					percentage
					class="mt-0"
					input-cols="2"
					label="What proportion of shares will be rolled over into a new company?"
					:testid="'corporation-proportions-rolled-over-'+testid"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2" v-model="corporation.additionalClausesToRecitals" :testid="'corporation-add-clause-to-recitals-'+testid+''"
			:disabled="isViewMode"/>
			<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="corporation.additionalClauses" :testid="'corporation-add-clause-'+testid+''"
			:disabled="isViewMode"/>
		</template>
	</div>
</template>

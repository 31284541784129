<script>
import formatMoney from '@/mixins/formatMoney';
export default {
	name: 'EqualizationPayment',
	mixins: [formatMoney],
	props: {
		label: {
			type: String,
			default: '',
		},
		propertyType: {
			type: String,
			default: 'assets',
		},
		summary: {
			type: Object,
			default: function () {
				return {
					value: 0,
					spouseOne: 0,
					spouseTwo: 0,
				};
			},
		},
		eqPaymentIncludes:false,
		eqUseFigure:false,
		eqPaymentAmount:0,
		rrspTransfer:'',
		rrspTransferAmount:0,
		isAssetView:{
			type: Boolean,
		}
	},
};
</script>

<template>
	<fragment>
		<b-tr>
			<b-td colspan="7" variant="success">
				{{ label }}
			</b-td>
			<template v-if="!isAssetView">
			<b-td variant="success" class="text-right">
				<span v-if="summary.spouseOne > 0">
					${{ formatMoney(summary.spouseOne) }}
				</span>
			</b-td>
			<b-td variant="success" class="text-right">
				<span v-if="summary.spouseTwo > 0">
					${{ formatMoney(summary.spouseTwo) }}
				</span>
			</b-td>
			<b-td
				variant="success"
				class="text-right"
			>
			</b-td>
		</template>
		</b-tr>
		<b-tr>
			<b-td colspan="7" variant="success">
				Exclusions to be Repaid to Other related to
				{{ propertyType === 'assets' ? 'shared' : 'registered' }}
				property
			</b-td>
			<template v-if="!isAssetView">
			<b-td variant="success" class="text-right">
				<span v-if="summary.spouseOneRepayExclusion > 0">
					${{ formatMoney(summary.spouseOneRepayExclusion) }}
				</span>
			</b-td>
			<b-td variant="success" class="text-right">
				<span v-if="summary.spouseTwoRepayExclusion > 0">
					${{ formatMoney(summary.spouseTwoRepayExclusion) }}
				</span>
			</b-td>
			<b-td
				variant="success"
				class="text-right"
			>
			</b-td>
			</template>
		</b-tr>
		<b-tr>
			<b-td colspan="7" variant="success">
				Overall Equalization Payment to balance
				{{ propertyType === 'assets' ? 'shared' : 'registered' }}
				property 
			</b-td>
			<template v-if="!isAssetView">
			<b-td
				:id="`${propertyType}-spouse-one-overall`"
				variant="success"
				class="text-right"
			>
				<span v-if="summary.spouseOneOverall > 0">
					${{ formatMoney(summary.spouseOneOverall) }}
				</span>
			</b-td>
			<b-td
				:id="`${propertyType}-spouse-two-overall`"
				variant="success"
				class="text-right"
			>
				<span v-if="summary.spouseTwoOverall > 0">
					${{ formatMoney(summary.spouseTwoOverall) }}
				</span>
			</b-td>
			<b-td
				variant="success"
				class="text-right"
			>
			</b-td>
		</template>
		</b-tr>
		<b-tr v-if="(!eqPaymentIncludes && propertyType == 'assets') || (propertyType == 'registered-assets' && 
																		 rrspTransfer=='no-transfer')">
			<b-td colspan="7" variant="success">
				Negotiated to: 
			</b-td>
			<template v-if="!isAssetView">
			<b-td
				:id="`${propertyType}-spouse-one-negotiated-none`"
				variant="success"
				class="text-right"
			>
				<span v-if="summary.spouseOneOverall > 0">
					${{ formatMoney(0.00) }}
				</span>
			</b-td>
			<b-td
				:id="`${propertyType}-spouse-two-negotiated-none`"
				variant="success"
				class="text-right"
			>
				<span v-if="summary.spouseTwoOverall > 0">
					${{ formatMoney(0.00) }}
				</span>
			</b-td>
			<b-td
				variant="success"
				class="text-right"
			>
			</b-td>
		</template>
		</b-tr>
		<b-tr v-if="(eqPaymentIncludes && !eqUseFigure)  || (propertyType == 'registered-assets' && 
															 rrspTransfer=='specific-amount')">
			<b-td colspan="7" variant="success">
				Negotiated to:
			</b-td>
			<template v-if="!isAssetView">
			<b-td
				:id="`${propertyType}-spouse-one-negotiated`"
				variant="success"
				class="text-right"
			>
				<span v-if="summary.spouseOneOverall > 0">
					<template v-if="eqPaymentIncludes && !eqUseFigure">${{ formatMoney(eqPaymentAmount) }}</template>
					<template v-if="propertyType == 'registered-assets' && rrspTransfer=='specific-amount'">${{ formatMoney(rrspTransferAmount) }}</template>
				</span>
			</b-td>
			<b-td
				:id="`${propertyType}-spouse-two-negotiated`"
				variant="success"
				class="text-right"
			>
				<span v-if="summary.spouseTwoOverall > 0">
					<!-- ${{ formatMoney(eqPaymentAmount) }} -->
					<template v-if="eqPaymentIncludes && !eqUseFigure">${{ formatMoney(eqPaymentAmount) }}</template>
					<template v-if="propertyType == 'registered-assets' && rrspTransfer=='specific-amount'">${{ formatMoney(rrspTransferAmount) }}</template>
				</span>
			</b-td>
			<b-td
				variant="success"
				class="text-right"
			>
			</b-td>
			</template>
		</b-tr>
		<b-tr>
			<b-td :colspan="isAssetView ? '5' : '10'"></b-td>
		</b-tr>
	</fragment>
</template>

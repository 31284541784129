<script>
import {hasEditPermission,currentRoleLevelName,trialActiveForClients} from '@/mixins/roleLevelPermissions';

export default {
	props: ['agreementData','hideForEdit','itemIndexToShow','forceAddNewItem'],
	data() {
		return {
			isViewMode : !hasEditPermission('section-property') 
		};
	},
	computed: {
		vehicles() {
			return this.agreementData.vehicles;
			// return this.agreementData.vehicles = this.agreementData.vehicles.map((vehicle)=>{
			// 	return vehicle.hasOwnProperty('additionalClausesToRecitals') ? vehicle  : {...vehicle, additionalClausesToRecitals: []};
			// });	
		},
		clientUserAgreement(){
			return trialActiveForClients() || currentRoleLevelName === 'client-sharing-user';
		}
	},
	methods: {
		newVehicle() {
			return {
				exclusions: [],
				additionalClauses: [],
				additionalClausesToRecitals: []
			};
		},
	},
	 mounted(){
		for (let i = 0; i < this.agreementData.vehicles.length; i++) {
			const vehicle = this.agreementData.vehicles[i];
			if (!vehicle.hasOwnProperty('additionalClausesToRecitals')) {
				this.$set(vehicle, 'additionalClausesToRecitals', []);
			}
		}
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Vehicles</h4>

		<list-item
			v-slot="{ item: vehicle, index: index }"
			v-model="vehicles"
			:object-prototype="newVehicle"
			:testid="'vehicle-'"
			:hideAddBtn=hideForEdit
			:isEditView=hideForEdit
			:itemIndexToShow=itemIndexToShow
			:forceAddNewItem=forceAddNewItem
			:disabled="isViewMode"
		>
		<template v-if="!forceAddNewItem || (forceAddNewItem && index == (vehicles.length-1))">
			<input-group
				v-model="vehicle.year"
				class="mb-2"
				input-cols="4"
				label="Year of the vehicle"
				reset-undefined
				:testid="'vehicle-make-year-'+ index"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="vehicle.make"
				class="mb-2"
				input-cols="4"
				label="Make of the vehicle"
				reset-undefined
				:testid="'vehicle-make-of-'+ index"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="vehicle.model"
				class="mb-2"
				input-cols="4"
				label="Model of the vehicle"
				reset-undefined
				:testid="'vehicle-model-'+ index"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="vehicle.value"
				reset-undefined
				class="my-3"
				dollar
				label="Value of the vehicle as of date of separation/date of agreement"
				:testid="'vehicle-value-'+ index"
				:disabled="isViewMode"
			/>

			<radio-group
				v-model="vehicle.owner"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				label="Whose name is the vehicle registered in?"
				reset-undefined
				partner
				select
				:options="['Joint']"
				:testid="'vehicle-registered-name-'+ index"
				:length="'long'"
				:disabled="isViewMode"
			/>
			<template v-if="!clientUserAgreement">
				<yes-no-choice
					v-model="vehicle.hasExclusions"
					reset-undefined
					label="Are there any exclusions related to the vehicle?"
					class="my-3"
					:testid="'vehicle-has-exclusions-'+ index"
					:disabled="isViewMode"
				/>

				<conditional-group class="my-3" :condition="vehicle.hasExclusions">
					<exclusion-table
						v-model="vehicle.exclusions"
						:agreement-data="agreementData"
						:testid="'vehicle-exclusion-'+ index + '-'"
						:disabled="isViewMode"
					/>
				</conditional-group>

				<radio-group
					v-model="vehicle.plan"
					class="my-3"
					:agreement-data="agreementData"
					input-cols="4"
					label="What will the owner do with the vehicle upon separation?"
					reset-undefined
					select
					:options="[
						{ value: 'keep', text: 'Keep' },
						{ value: 'transfer', text: 'Transfer' },
						{
							value: 'sell',
							text: 'Sell and split the proceeds equally',
						},
					]"
					:testid="'vehicle-upon-separation'+ index"
					:length="'long'"
					:disabled="isViewMode"
				/>

				<conditional-group
					class="my-3"
					:condition="vehicle.plan === 'transfer'"
				>
					<radio-group
						v-model="vehicle.recipient"
						class="my-3"
						:agreement-data="agreementData"
						input-cols="4"
						label="Who will the vehicle registration be transferred to?"
						reset-undefined
						partner
						select
						:testid="'vehicle-registration-transferred-to-'+ index"
						:length="'long'"
						:disabled="isViewMode"
					/>
				</conditional-group>

				<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2" v-model="vehicle.additionalClausesToRecitals" :testid="'vehicle-add-clause-to-recitals-'+ index + '-'"
					:disabled="isViewMode"/>
				<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="vehicle.additionalClauses" :testid="'vehicle-add-clause-'+ index + '-'"
					:disabled="isViewMode"/>
			</template>
		</template>
		</list-item>
	</b-card>
</template>

<script>
import {hasEditPermission,currentRoleLevelName,trialActiveForClients} from '@/mixins/roleLevelPermissions';

export default {
	props: ['agreementData','hideForEdit','itemIndexToShow','forceAddNewItem'],
	data() {
		return {
			isViewMode : !hasEditPermission('section-property') 
		};
	},
	computed: {
		accounts() {
			return this.agreementData.accounts
			// return this.agreementData.accounts = this.agreementData.accounts.map((account)=>{
			// 	return account.hasOwnProperty('additionalClausesToRecitals') ? account  : {...account, additionalClausesToRecitals: []};
			// });
		},
		previouslyDividedAccount() {
			return this.agreementData.agreement;
		},
		clientUserAgreement(){
			return trialActiveForClients() || currentRoleLevelName === 'client-sharing-user';
		}
	},
	methods: {
		newFinancialAcount() {
			return {
				exclusions: [],
				additionalClauses: [],
				additionalClausesToRecitals:[]
			};
		},
		accountOptions(account) {
			let options = [
				{ text: 'Keep', value: 'keep' },
				{ text: 'Transfer', value: 'transfer' },
				{
					text: 'Divide contents and close the account',
					value: 'close',
				},
			];
			if (
				account.ownership &&
				account.ownership.toLowerCase() == 'joint'
			) {
				options = [...options, { text: 'Close', value: 'close' }];
			}
			return options;
		},
	},
	mounted(){
		for (let i = 0; i < this.agreementData.accounts.length; i++) {
			const account = this.agreementData.accounts[i];
			if (!account.hasOwnProperty('additionalClausesToRecitals')) {
				this.$set(account, 'additionalClausesToRecitals', []);
			}
		}
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Financial Accounts</h4>
		<p>
			Please include all financial accounts that should be factored 
			into the financials of this Agreement below. Include bank
			accounts, investment accounts and any other unregistered 
			accounts. RRSP accounts should be added in the RRSP section.
		</p>
			<yes-no-choice
				v-if="!clientUserAgreement"
				v-model="previouslyDividedAccount.previouslyDivided"
				reset-undefined
				label="Do the parties have previously divided bank accounts that will not be factored into the financials of this Agreement?"
				:testid="'financial-account-has-bank-accounts'"
				class="my-3"
				:options="[
					{ value: true, text: 'Yes' },
					{
						value: false,
						text: 'No',
					},
				]"
				:disabled="isViewMode"
			/>
		<list-item
			v-slot="{ item: account, index: index }"
			v-model="accounts"
			:object-prototype="newFinancialAcount"
			:testid="'financial-account-'"
			:hideAddBtn=hideForEdit
			:isEditView=hideForEdit
			:itemIndexToShow=itemIndexToShow
			:forceAddNewItem=forceAddNewItem
			:disabled="isViewMode"
		>
		<template v-if="!forceAddNewItem || (forceAddNewItem && index == (accounts.length-1))">
			<input-group
				v-model="account.institutionName"
				class="mb-2"
				input-cols="8"
				label="Name of financial institution"
				reset-undefined
				:testid="'financial-account-institution-name-'+index"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="account.type"
				class="my-3"
				reset-undefined
				input-cols="4"
				label="Type of account"
				placeholder="ie: Savings Account"
				:testid="'financial-account-type-'+index"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="account.lastFourDigits"
				class="my-3"
				reset-undefined
				input-cols="4"
				label="Last four digits of the account"
				placeholder="Digits (Optional)"
				:testid="'financial-account-last-four-digits-'+index"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="account.balance"
				reset-undefined
				class="my-3"
				dollar
				label="Account balance as of the date of separation/date of agreement"
				:testid="'financial-account-balance-'+index"
				:disabled="isViewMode"
			/>

			<radio-group
				v-model="account.ownership"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				label="How is the financial account owned?"
				reset-undefined
				partner
				select
				:options="['Jointly']"
				:testid="'financial-account-ownership-'+index"
				:length="'long'"
				:disabled="isViewMode"
			/>
			<template v-if="!clientUserAgreement">
				<radio-group
					v-model="account.plan"
					class="my-2"
					input-cols="8"
					label="What will the owner do with the financial account upon separation?"
					reset-undefined
					select
					:options="accountOptions(account)"
					:testid="'financial-account-plan-'+index"
					:length="'long'"
					:disabled="isViewMode"
				/>

				<conditional-group
					class="my-3"
					:condition="account.plan === 'transfer'"
				>
					<radio-group
						v-model="account.recipient"
						:agreement-data="agreementData"
						class="mb-2"
						input-cols="4"
						label="Who will the financial account be transferred to?"
						partner
						reset-undefined
						select
						:testid="'financial-account-transferred-to-'+index"
						:length="'long'"
						:disabled="isViewMode"
					/>
				</conditional-group>

				<yes-no-choice
					v-model="account.hasExclusions"
					reset-undefined
					label="Are there any exclusions related to the financial account?"
					class="my-3"
					:testid="'financial-account-has-exclusions-'+index"
					:disabled="isViewMode"
				/>

				<conditional-group class="my-3" :condition="account.hasExclusions">
					<exclusion-table
						v-model="account.exclusions"
						:agreement-data="agreementData"
						:testid="'financial-account-exclusion-'+index+'-'"
						:disabled="isViewMode"
					/>
				</conditional-group>

				<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2" v-model="account.additionalClausesToRecitals" :testid="'financial-account-add-clause-to-recitals-'+index+'-'"
					:disabled="isViewMode"/>
				<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="account.additionalClauses" :testid="'financial-account-add-clause-'+index+'-'"
					:disabled="isViewMode"/>
			</template>
		</template>
		</list-item>
	</b-card>
</template>


<script>
import Corporation, { newCorporation } from './Corporation';
import partnerChoices from '@/mixins/partnerChoices';
import {hasEditPermission} from '@/mixins/roleLevelPermissions';

export default {
	components: {
		Corporation,
	},
	mixins: [partnerChoices],
	props: ['agreementData','hideForEdit','itemIndexToShow','forceAddNewItem'],
	data() {
		return {
			isViewMode : !hasEditPermission('section-property') 
		};
	},
	methods: {
		newCorporation,
	},
	// computed:{
	// 	corporations(){
	// 		return this.agreementData.corporations
			// return this.agreementData.corporations = this.agreementData.corporations.map((corporation)=>{
			// 	return corporation.hasOwnProperty('additionalClausesToRecitals') ? corporation  : {...corporation, additionalClausesToRecitals: []};
			// });	
	// 	}
	// },
	mounted(){
		for (let i = 0; i < this.agreementData.corporations.length; i++) {
			const corporation = this.agreementData.corporations[i];
			if (!corporation.hasOwnProperty('additionalClausesToRecitals')) {
				this.$set(corporation, 'additionalClausesToRecitals', []);
			}
		}
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Corporations</h4>

		<p>
			Important: This section is relevant only to corporations that are
			controlled by one or both of the parties. Non-controlling amounts of
			shares in other corporations should be listed in the financial
			assets section. Proportions of shares refer to the here are with
			reference to the division of shares between the parties, not the
			overall structure of the corporation.
		</p>

		<list-item
			v-slot="{ item: corporation, index: index }"
			v-model="agreementData.corporations"
			:object-prototype="newCorporation"
			:testid="'corporation-'"
			:hideAddBtn=hideForEdit
			:isEditView=hideForEdit
			:itemIndexToShow=itemIndexToShow
			:forceAddNewItem=forceAddNewItem
			:disabled="isViewMode"
		>
		<template v-if="!forceAddNewItem || (forceAddNewItem && index == (agreementData.corporations.length-1))">
			<corporation
				v-model="corporation"
				:agreement-data="agreementData"
				:testid="index"
				:hideForEdit="hideForEdit"
			/>
		</template>
		</list-item>
	</b-card>
</template>

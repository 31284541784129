<script>
import update from 'immutability-helper';
import AdditionalRealProperty from './AdditionalRealProperty';
import {hasEditPermission} from '@/mixins/roleLevelPermissions';

function newProperty() {
	return {
		address: {},
		exclusions: [],
		liabilities: [],
		additionalClauses: [],
		additionalClausesToRecitals: []
	};
}

export default {
	components: {
		AdditionalRealProperty,
	},
	props: ['agreementData','hideForEdit','itemIndexToShow','forceAddNewItem'],
	data() {
		return {
			isViewMode : !hasEditPermission('section-property') 
		};
	},
	methods: {
		newProperty,
		updateAdditionalRealProperties(value, index) {
			const tempProperties = update(this.agreementData.properties, {
				[index]: { $set: value },
			});

			this.$store.dispatch('updateAgreementProperty', {
				property: 'properties',
				value: tempProperties,
			});
		},
		parseToString(index){
			return index.toString()
		}
	},
	mounted(){
		for (let i = 0; i < this.agreementData.properties.length; i++) {
			const property = this.agreementData.properties[i];
			if (!property.hasOwnProperty('additionalClausesToRecitals')) {
				this.$set(property, 'additionalClausesToRecitals', []);
			}
		}		
	}
	// computed:{
	// 	properties(){
	// 		return this.agreementData.properties =  this.agreementData.properties.map((property)=>{
	// 			return property.hasOwnProperty('additionalClausesToRecitals') ? property  : {...property, additionalClausesToRecitals: []};
	// 		});	
	// 	}
	// }
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Additional Real Property</h4>

		<list-item
			v-slot="{ item: additionalRealProperty, index: index }"
			v-model="agreementData.properties"
			new-label="Add property"
			:object-prototype="newProperty"
			:testid="'additional-property-'"
			:hideAddBtn=hideForEdit
			:isEditView=hideForEdit
			:itemIndexToShow=itemIndexToShow
			:forceAddNewItem=forceAddNewItem
			:disabled="isViewMode"
		>
			<template v-if="!forceAddNewItem || (forceAddNewItem && index == (agreementData.properties.length-1))">
			<additional-real-property
				v-model="additionalRealProperty"
				:agreement-data="agreementData"
				:index="index"
				:propertyIndex="index"
				:update-additional-real-properties="
					updateAdditionalRealProperties
				"
				:testid="parseToString(index)"
			/>
			</template>
		</list-item>
	</b-card>
</template>

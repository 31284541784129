<script>
export default {
	name: 'HeaderRow',
	props: ['columns'],
};
</script>

<template>
	<b-tr>
		<b-th
			v-for="col in columns"
			v-if="!col.isAssetView"
			:id="col.id"
			:key="col.field"
			:variant="col.variant"
			:class="col.class"
			:name="col.name"
			:style="`width: ${col.width || '10vw'}`"
			>{{ col.label }}</b-th
		>
	</b-tr>
</template>

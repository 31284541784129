import property from '@/mixins/property';
import {
	getAssetsSummary,
	getLiabilitySummary,
	getNetSummary,
	propertyEqualization,
	getRegisteredAssetSummary,
	registeredPropertyEqualization,
	sumExclusionsToBeRepaid,
} from '@/lib/equalizationCalculations';

export default {
	mixins: [property],
	methods: {
		sumExclusionsToBeRepaid,
	},
	computed: {
		assetsSummary() {
			return getAssetsSummary(this.allProperty);
		},
		liabilitySummary() {
			return getLiabilitySummary(this.liabilities);
		},
		netSummary() {
			return getNetSummary(this.allProperty, this.liabilities);
		},
		registeredAssetSummary() {
			return getRegisteredAssetSummary(this.registeredAccounts);
		},
		propertyEqualization() {
			return propertyEqualization(this.allProperty, this.liabilities);
		},
		registeredPropertyEqualization() {
			return registeredPropertyEqualization(this.registeredAccounts);
		},
		overallEqualization() {
			return this.propertyEqualization.spouseOneOverall >
				this.propertyEqualization.spouseTwoOverall
				? this.propertyEqualization.spouseOneOverall
				: this.propertyEqualization.spouseTwoOverall;
		},
		transferredFrom() {
			return this.propertyEqualization.spouseOneOverall >
				this.propertyEqualization.spouseTwoOverall
				? this.partner1Name
				: this.partner2Name;
		},
		transferredTo() {
			return this.propertyEqualization.spouseOneOverall >
				this.propertyEqualization.spouseTwoOverall
				? this.partner2Name
				: this.partner1Name;
		},
	},
};

export default [
	{ text: '1st', value: 'first' },
	{ text: '2nd', value: 'second' },
	{ text: '3rd', value: 'third' },
	{ text: '4th', value: 'fourth' },
	{ text: '5th', value: 'fifth' },
	{ text: '6th', value: 'sixth' },
	{ text: '7th', value: 'seventh' },
	{ text: '8th', value: 'eighth' },
	{ text: '9th', value: 'ninth' },
	{ text: '10th', value: '10th' },
	{ text: '11th', value: '11th' },
	{ text: '12th', value: '12th' },
	{ text: '13th', value: '13th' },
	{ text: '14th', value: '14th' },
	{ text: '15th', value: '15th' },
	{ text: '16th', value: '16th' },
	{ text: '17th', value: '17th' },
	{ text: '18th', value: '18th' },
	{ text: '19th', value: '19th' },
	{ text: '20th', value: '20th' },
	{ text: '21st', value: '21st' },
	{ text: '22nd', value: '22nd' },
	{ text: '23rd', value: '23rd' },
	{ text: '24th', value: '24th' },
	{ text: '25th', value: '25th' },
	{ text: '26th', value: '26th' },
	{ text: '27th', value: '27th' },
	{ text: '28th', value: '28th' },
	{ text: '29th', value: '29th' },
	{ text: '30th', value: '30th' },
	{ text: '31st', value: '31st' },
];

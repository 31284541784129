import format from 'date-fns/format';

const monthNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
let tempDate = new Date();

const months = monthNumbers.map((monthNum) => {
	tempDate.setMonth(monthNum);
	return format(tempDate.setMonth(monthNum), 'LLLL');
});

export default months;

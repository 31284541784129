import { pureFilters } from '@/lib/agreement/filters';

export default {
	props: {
		agreementData: {
			type: Object,
			required: true,
		},
	},
	computed: {
		childrenNames() {
			return this.agreementData.children.map(pureFilters.preferredName);
		},
	},
};

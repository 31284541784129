import nunjucks from 'nunjucks';

import clauses from './clauses.njk';
import ALBERTA from './ALBERTA.njk';
import BRITISH_COLUMBIA from './BRITISH_COLUMBIA.njk';
import MANITOBA from './MANITOBA.njk';
import NEW_BRUNSWICK from './NEW_BRUNSWICK.njk';
import NEWFOUNDLAND from './NEWFOUNDLAND.njk';
import NOVA_SCOTIA from './NOVA_SCOTIA.njk';
import NUNAVUT from './NUNAVUT.njk';
import NORTHWEST_TERRITORIES from './NORTHWEST_TERRITORIES.njk';
import ONTARIO from './ONTARIO.njk';
import PRINCE_EDWARD_ISLAND from './PRINCE_EDWARD_ISLAND.njk';
import SASKATCHEWAN from './SASKATCHEWAN.njk';
import YUKON from './YUKON.njk';
import filters, { getExclusions } from './filters';
import {trialActiveForClients} from '@/mixins/roleLevelPermissions';

const env = nunjucks.configure({
	autoescape: false,
	dev: process.env.NODE_ENV === 'development',
});

for (let [key, value] of Object.entries(filters)) {
	env.addFilter(key, value);
}

if (process.env.NODE_ENV === 'development') {
	env.addGlobal('getVars', function () {
		return this.getVariables();
	});

	env.addGlobal('getContext', function () {
		return this.ctx;
	});

	env.addFilter('prettyPrint', function (string) {
		return JSON.stringify(string, null, 2);
	});
}

export default function ({ agreementData }) {
	let clausesFile = assignClausesFile(agreementData.agreement.updatedProvinceOfAgreement);

	return nunjucks.renderString(clausesFile, { ...agreementData, getExclusions, trialActiveForClients });
}

const assignClausesFile =(updatedProvinceOfAgreement)=>{
	let clauseFile
	switch (updatedProvinceOfAgreement) {
		case 'Alberta':
		clauseFile = ALBERTA;
		break;
		case 'British Columbia':
		clauseFile = BRITISH_COLUMBIA;
		break;
		case 'Manitoba':
		clauseFile = MANITOBA;
		break;
		case 'New Brunswick':
		clauseFile = NEW_BRUNSWICK;
		break;
		case 'Newfoundland and Labrador':
		clauseFile = NEWFOUNDLAND;
		break;
		case 'Nova Scotia':
		clauseFile = NOVA_SCOTIA;
		break;
		case 'Nunavut':
		clauseFile = NUNAVUT;
		break;

		case 'Northwest Territories':
		clauseFile = NORTHWEST_TERRITORIES;
		break;
		
		case 'Ontario':
		clauseFile = ONTARIO;
		break;
		
		case 'Prince Edward Island':
		clauseFile = PRINCE_EDWARD_ISLAND;
		break;
		
		case 'Saskatchewan':
		clauseFile = SASKATCHEWAN;
		break;
		
		case 'Yukon':
		clauseFile = YUKON;
		break;

		default:
		clauseFile = BRITISH_COLUMBIA;
		break;
	}
	return clauseFile;

}

<script>
export default {
	name: 'SectionHeader',
	props: ['label','isAssetView'],
};
</script>

<template>
	<b-tr>
		<b-th :colspan="isAssetView ? '5' : '10'" variant="dark">{{ label }}</b-th>
	</b-tr>
</template>

<script>
import {  BIconPlus, BIconPencilFill } from 'bootstrap-vue';
import formatMoney from '@/mixins/formatMoney';

import property from '@/mixins/property';
import { hasEditPermission,hasAddPermission } from '@/mixins/roleLevelPermissions';

export default {
	name: 'TableSection',
	components: {
		// BIconPencilSquare,
		BIconPencilFill,
		BIconPlus
	},
	mixins: [formatMoney,property],
	props: ['items', 'label','agreementData','labelId','isAssetView'],
	computed: {
		numberOfItems() {
			return this.items && this.items.length ? this.items.length : 0;
		},
		agreementFilledDetails() {
			return this.$store.getters.agreementDetails;
		},
		modalOptions(){
			return this.editModalOptions;
		},
		getCurrentRoute(){
			return this.$route.name;
		}
	},
	methods: {
		showEditModal(modalId,sectionLabelId,items,item,index){
			this.editModalOptions['modalId']=modalId;
			if(item.type){
				this.editModalOptions['sectionLabelId']=item.type;
				if(item.type=="additional-property"){
					let familyResidencePresentInList = items.find(localItem => localItem.type && localItem.type=='family-residence');
					if(familyResidencePresentInList)
						this.editModalOptions['index']=item.initialIndex;
					else
						this.editModalOptions['index']=item.initialIndex;
				// }
				//  else if(item.type=='corporation'){
				// 	let businessPropertiesInTheList = items.filter(localItem => localItem.type && localItem.type=='business');
				// 	if(businessPropertiesInTheList && businessPropertiesInTheList.length){
				// 		this.editModalOptions['index']=index - businessPropertiesInTheList.length;
				// 	} else{
				// 		this.editModalOptions['index']=index;
				// 	}
				// } else if(item.type=='RRSP'){
				// 	let RESPInTheList = items.filter(localItem => localItem.type && localItem.type=='RESP');
				// 	if(RESPInTheList && RESPInTheList.length){
				// 		this.editModalOptions['index']=index - RESPInTheList.length;
				// 	} else{
				// 		this.editModalOptions['index']=index;
				// 	}
				} else {
					this.editModalOptions['index']=(item.initialIndex != undefined && item.initialIndex != null) ? item.initialIndex : index;
				}
			} else  {
				this.editModalOptions['sectionLabelId']=sectionLabelId;
				this.editModalOptions['index']= (item.initialIndex!= undefined && item.initialIndex != null) ? item.initialIndex : index;
			}
			this.$emit('openEditModal', this.editModalOptions)
		},
		showAddModal(buttonId){
			this.addModalOptions['modalId']='add-modal';
			this.addModalOptions['sectionLabelId']=buttonId
			this.$emit('openAddModal', this.addModalOptions)
		},
		getAddButtons(){
			let buttons = [];
			const propertyRecitals = (section) =>
				this.agreementFilledDetails && this.agreementFilledDetails.agreement &&
				this.agreementFilledDetails.agreement.propertyRecitals &&
				this.agreementFilledDetails.agreement.propertyRecitals.length &&
				this.agreementFilledDetails.agreement.propertyRecitals.includes(section);
			
			if(this.labelId == 'corporate-interests'){
				if (propertyRecitals('Corporation'))
					buttons.push({buttonId : 'corporation', buttonName:'Add Corporation'});
				if (propertyRecitals('Business'))
					buttons.push({buttonId : 'business', buttonName:'Add Business'}); 
			} else if(this.labelId == 'registeredAccounts'){
				if (propertyRecitals('RRSPs'))
					buttons.push({buttonId : 'RRSP', buttonName:'Add RRSP'});
				if (propertyRecitals('RESPs'))
					buttons.push({buttonId : 'RESP', buttonName:'Add RESP'}); 
			} else {
				buttons.push({buttonId : this.labelId, buttonName:'Add '+ this.label});
			}
			
			return buttons;
		}
	},
	data(){
		return {
			editModalOptions :{
				sectionLabelId :'',
				index : undefined,
				modalId:''
			},
			addModalOptions:{
				sectionLabelId :'',
				modalId:''
			},
			assetView:localStorage.getItem('isAssetView'),
			isEditEnabled : hasEditPermission('property-schedule'),
			isAddEnabled : hasAddPermission('property-schedule') 
		}
	},
	mounted(){
		this.assetView=localStorage.getItem('isAssetView')
		if(this.assetView){
		for (let i = 0; i < this.items.length; i++){
			['shared', 'spouceOneExclusions','spouceTwoExclusions','spouseOne','spouseTwo'].forEach(e => delete this.items[i][e])
		}
		}
	}
 };
</script>

<style>
.description {
	padding-left: 12px !important;
}
.bi-pencil-fill{
	cursor: pointer;
	transition: transform .3s;
}
.bi-pencil-fill:hover{
	transform: scale(1.25);
}
.bottom-align{
	vertical-align: bottom !important;
	text-align-last: center;
}
.section-header .btn{
	margin: 0 0.25rem;
}
.items-container{
	width: 100%;
    display: contents;
}
.bi-plus{
	cursor: pointer;
	transition: transform .3s;
}
.bi-plus:hover{
	transform: scale(1.5);
}

.none-display{
	display: none !important;
}
</style>

<template>
	<b-tbody>
		<b-tr v-if="label" class="section-header">
			<b-th colspan="3">{{ label }} 
					<!-- <b-button  v-for="(addButton, index) in getAddButtons()" :key="index"
							@click="showAddModal(addButton.buttonId)"
							size="sm"
							variant="navy"
							:data-testid="'add-'+ addButton.buttonId"
					>
						{{addButton.buttonName}}
					</b-button> -->
					<span class="print-hide" v-if="labelId === 'real-property' ? (agreementData && agreementData.agreement && agreementData.agreement.propertyRecitals.includes('Additional Real Property')) ? true: false : true">
						<b-icon-plus v-if="isAddEnabled" font-scale="1.5" variant="info" :data-testid="'add-'+ labelId" @click="showAddModal(labelId)"/>
					</span>
			</b-th>
			<b-td variant="success"></b-td>
			<template v-if="!isAssetView">
			<b-td></b-td>
			<b-td></b-td>
			<b-td variant="success"></b-td>
			<b-td></b-td>
			<b-td></b-td>
			<b-td class="print-hide" ></b-td>
		</template>
		</b-tr>
		<ul v-if="items" class="items-container">
			<!-- {{ items }} -->
		<fragment v-for="(item, index) in items" :key="index">
			<b-tr v-show="item.description.trim()">
				<b-td class="description" :style="item.descriptionStyle || ''" :data-testid="labelId+'-description-'+index">
					{{ item.description }}
				</b-td>
				<b-td class="align-bottom" :data-testid="labelId+'-ownership-'+index">{{ item.ownership }}</b-td>
				<b-td :data-testid="labelId+'-asset-'+index" class="align-bottom text-center"><template v-if="item.type=='corporation' && item.shared && item.shared != '-'">
					${{ formatMoney(item.shared) }}</template>
					<fragment v-if="item.notes">
						<table
							class="table b-table table-sm b-table-caption-top"
							style="margin-bottom: 0px !important"
						>
							<b-tbody>
								<tr
									v-for="(note, index) in item.notes"
									:key="index"
									class="w-100"
								>
									<td
										:class="`text-left w-100 ${
											note.class ? note.class : ''
										}`"
									>
										{{ note.label }}
									</td>
									<td
										:class="`text-right w-100 ${
											note.class ? note.class : ''
										}`"
									>
										${{ formatMoney(note.value) }}
									</td>
								</tr>
							</b-tbody>
						</table>
					</fragment>
					<fragment v-else>{{ item.comments }}</fragment>
				</b-td>
				<b-td class="text-right align-bottom" variant="success" :data-testid="labelId+'-value-'+index">
					<!-- <span v-if="item.type=='corporation' && item.value != '-' &&
					(item.spouseOne==0 && item.spouseTwo==0)">
						${{ formatMoney(item.value) }}
					</span> -->
					<span v-if="item.type!='corporation' && item.value != '-'">
						${{ formatMoney(item.value) }}
					</span>
					<!-- <span v-else-if="item.value != '-'">
						${{ formatMoney(item.value) }}
					</span> -->
					<span v-else-if="item.details"></span>
					<span v-else-if="item.value">{{ item.value }}</span>
				</b-td>
				<template v-if="!isAssetView">
				<b-td class="text-right align-bottom" :data-testid="labelId+'-spouceOneExclusions-'+index">
					<span v-if="item.spouceOneExclusions">
						<span
							v-if="
								!item.details ||
								(item.details && !item.details.length)
							"
							>${{ formatMoney(item.spouceOneExclusions) }}</span
						>
					</span>
				</b-td>
				<b-td class="text-right align-bottom" :data-testid="labelId+'-spouceTwoExclusions-'+index">
					<span v-if="item.spouceTwoExclusions">
						<span
							v-if="
								!item.details ||
								(item.details && !item.details.length)
							"
							>${{ formatMoney(item.spouceTwoExclusions) }}</span
						>
					</span>
				</b-td>
				<b-td class="text-right align-bottom" variant="success" :data-testid="labelId+'-shared-'+index">
					<span v-if="item.type=='corporation' && item.shared != '-' &&
					(item.spouseOne==0 && item.spouseTwo==0)">
						${{ formatMoney(item.shared) }}
					</span>
					<span v-if="item.type!='corporation' && item.shared != '-'">
						${{ formatMoney(item.shared) }}
					</span>
					<span v-else-if="item.type!='corporation' && item.shared">{{ item.shared }}</span>
				</b-td>
				<b-td class="text-right align-bottom" :data-testid="labelId+'-spouseOne-retained-'+index">
					<span
						v-if="
							(item.spouseOne ||
								item.keeper == '0' ||
								item.keeper == 'joint') &&
							(!item.details ||
								(item.details && !item.details.length))
						"
					>
						${{ formatMoney(item.spouseOne) }}
					</span>
				</b-td>
				<b-td class="text-right align-bottom" :data-testid="labelId+'-spouseTwo-retained-'+index">
					<span
						v-if="
							(item.spouseTwo ||
								item.keeper == '1' ||
								item.keeper == 'joint') &&
							(!item.details ||
								(item.details && !item.details.length))
						"
					>
						${{ formatMoney(item.spouseTwo) }}
					</span>
				</b-td>
			</template>
				<b-td class="print-hide bottom-align">
					<!-- <b-button size="sm" variant="primary" :data-testid="(labelId+'-'+index)" @click.self="showEditModal('edit-modal',labelId,items,item,index)">Edit</b-button> -->
					<b-icon-pencil-fill font-scale="1.2" v-if="getCurrentRoute !='preview' && isEditEnabled" variant="primary" :data-testid="(labelId+'-'+index)" @click="showEditModal('edit-modal',labelId,items,item,index)"/>
					<!-- <b-button size="sm" variant="primary" v-b-modal.edit-modal>Edit</b-button> -->
				</b-td>
			</b-tr>
			<b-tr v-for="(item, index) in item.details" :key="index">
				<b-td class="description" :style="item.descriptionStyle || ''">
					{{ item.description }}
				</b-td>
				<b-td>{{ item.ownership }}</b-td>
				<b-td class="text-center">{{ item.comments }}</b-td>
				<b-td class="text-right" variant="success">
					<span v-if="item.value != '-'">
						${{ formatMoney(item.value) }}
					</span>
					<span v-else-if="item.value">{{ item.value }}</span>
				</b-td>
				<template v-if="!isAssetView">
				<b-td class="text-right">
					<span v-if="item.spouceOneExclusions">
						${{ formatMoney(item.spouceOneExclusions) }}
					</span>
				</b-td>
				<b-td class="text-right">
					<span v-if="item.spouceTwoExclusions">
						${{ formatMoney(item.spouceTwoExclusions) }}
					</span>
				</b-td>
				<b-td class="text-right" variant="success">
					${{ formatMoney(item.shared) }}
				</b-td>
				<b-td class="text-right">
					<span
						v-if="
							item.spouseOne ||
							item.keeper == '0' ||
							item.keeper == 'joint'
						"
					>
						${{ formatMoney(item.spouseOne) }}
					</span>
				</b-td>
				<b-td class="text-right">
					<span
						v-if="
							item.spouseTwo ||
							item.keeper == '1' ||
							item.keeper == 'joint'
						"
					>
						${{ formatMoney(item.spouseTwo) }}
					</span>
				</b-td>
			</template>
			</b-tr>
		</fragment>
		</ul>
	</b-tbody>
	
	
</template>

<script>
import partnerChoices from '@/mixins/partnerChoices';
import {hasEditPermission,currentRoleLevelName,trialActiveForClients} from '@/mixins/roleLevelPermissions';

export default {
	mixins: [partnerChoices],
	props: ['agreementData','hideForEdit','itemIndexToShow','forceAddNewItem'],
	data() {
		return {
			isViewMode : !hasEditPermission('section-property') 
		};
	},
	methods: {
		newBusiness() {
			return {
				address: {},
				exclusions: [],
				additionalClauses: [],
				additionalClausesToRecitals: []
			};
		},
	},
	computed:{
		clientUserAgreement(){
			return trialActiveForClients() || currentRoleLevelName === 'client-sharing-user';
		}
	},
	mounted(){
		for (let i = 0; i < this.agreementData.businesses.length; i++) {
			const business = this.agreementData.businesses[i];
			if (!business.hasOwnProperty('additionalClausesToRecitals')) {
				this.$set(business, 'additionalClausesToRecitals', []);
			}
		}	
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Businesses</h4>

		<list-item
			v-slot="{ item: business, index: index }"
			v-model="agreementData.businesses"
			:object-prototype="newBusiness"
			:testid="'business-name-'"
			:hideAddBtn=hideForEdit
			:isEditView=hideForEdit
			:itemIndexToShow=itemIndexToShow
			:forceAddNewItem=forceAddNewItem
			:disabled="isViewMode"
		>
		<template v-if="!forceAddNewItem || (forceAddNewItem && index == (agreementData.businesses.length-1))">
			<input-group
				v-model="business.name"
				reset-undefined
				class="mb-2"
				input-cols="8"
				label="Name"
				:testid="'business-name-'+index"
				:disabled="isViewMode"
			/>

			<address-input-group v-model="business.address" class="my-3" :testid="'business-address-'+index" :disabled="isViewMode"/>

			<radio-group
				v-model="business.ownership"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				label="Ownership"
				reset-undefined
				partner
				select
				:options="['Jointly']"
				:testid="'business-ownership-'+index"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="business.valuation"
				reset-undefined
				class="my-3"
				dollar
				input-cols="4"
				label="What is the value of the business?"
				:testid="'business-valuation-'+index"
				:disabled="isViewMode"
			/>
			<template v-if="!clientUserAgreement">
				<yes-no-choice
					v-model="business.professionalValuation"
					reset-undefined
					label="Did this value come from a professional business valuation?"
					class="my-2"
					:testid="'business-professional-valuation-'+index"
					:disabled="isViewMode"
				/>

				<conditional-group
					class="my-3"
					:condition="business.professionalValuation"
				>
					<date-input
						v-model="business.valuationDate"
						reset-undefined
						label="Date of valuation"
						:testid="'business-valuation-date-'+index"
						:disabled="isViewMode"
					/>
				</conditional-group>

				<yes-no-choice
					v-model="business.hasExclusions"
					reset-undefined
					label="Are there any exclusions related to the business?"
					class="my-3"
					:testid="'business-has-exclusions-'+index"
					:disabled="isViewMode"
				/>

				<conditional-group class="my-3" :condition="business.hasExclusions">
					<exclusion-table
						v-model="business.exclusions"
						:agreement-data="agreementData"
						:testid="'business-exclusion-'+index+'-'"
						:disabled="isViewMode"
					/>
				</conditional-group>

				<radio-group
					v-model="business.plan"
					class="my-2"
					:agreement-data="agreementData"
					input-cols="4"
					label="What will the owner do with the business upon separation?"
					reset-undefined
					select
					:options="['Keep', 'Transfer', 'Sell']"
					:testid="'business-upon-seperation-'+index"
					:disabled="isViewMode"
				/>

				<conditional-group
					class="my-3"
					:condition="
						business.plan === 'Transfer' &&
						business.ownership === 'Jointly'
					"
				>
					<radio-group
						v-model="business.transferor"
						class="mb-2"
						:agreement-data="agreementData"
						input-cols="4"
						label="Given that the parties jointly own the business, who is transferring their ownership?"
						reset-undefined
						partner
						select
						:testid="'business-transferor-'+index"
						:disabled="isViewMode"
					/>
				</conditional-group>

				<additional-clauses class="mt-2" :newLabel="'Add Clause to Recitals'" :title="'Additional Clauses into Recitals'" v-model="business.additionalClausesToRecitals" :testid="'business-add-clause-to-recitals-'+index+'-'"
				:disabled="isViewMode"/>
				<additional-clauses class="mt-2" :newLabel="'Add Clause to Section'" :title="'Additional Clauses into Sections'" v-model="business.additionalClauses" :testid="'business-add-clause-'+index+'-'"
				:disabled="isViewMode"/>
			</template>
		</template>
		</list-item>
	</b-card>
</template>

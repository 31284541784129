<script>
import equalizationCalculations from '@/mixins/equalizationCalculations';
import partnerNames from '@/mixins/partnerNames';
import formatMoney from '@/mixins/formatMoney';
import {hasEditPermission,currentRoleLevelName,trialActiveForClients} from '@/mixins/roleLevelPermissions';

export default {
	mixins: [equalizationCalculations, partnerNames, formatMoney],
	props: ['agreementData','hideForEdit','itemIndexToShow','forceAddNewItem'],
	data() {
		return {
			isViewMode : !hasEditPermission('section-property') 
		};
	},
	computed: {
		accounts() {
			return this.agreementData.registeredRetirementSavingsAccounts;
			// return this.agreementData.registeredRetirementSavingsAccounts = this.agreementData.registeredRetirementSavingsAccounts.map((rrsp)=>{
			// 	return rrsp.hasOwnProperty('additionalClausesToRecitals') ? rrsp  : {...rrsp, additionalClausesToRecitals: []};
			// });	
		},
		registeredRetirementSavings() {
			return this.agreementData.registeredRetirementSavings;
		},
		accountOptions() {
			return this.agreementData.registeredRetirementSavingsAccounts.map(
				({ name, lastFourDigits }) =>
					`${name || ''} RRSP ${lastFourDigits || ''}`
			);
		},
		overallEqualization() {
			return this.registeredPropertyEqualization.spouseOneOverall >
				this.registeredPropertyEqualization.spouseTwoOverall
				? this.registeredPropertyEqualization.spouseOneOverall
				: this.registeredPropertyEqualization.spouseTwoOverall;
		},
		transferredFrom() {
			return this.registeredPropertyEqualization.spouseOneOverall >
				this.registeredPropertyEqualization.spouseTwoOverall
				? this.partner1Name
				: this.partner2Name;
		},
		transferredTo() {
			return this.registeredPropertyEqualization.spouseOneOverall >
				this.registeredPropertyEqualization.spouseTwoOverall
				? this.partner2Name
				: this.partner1Name;
		},
		clientUserAgreement(){
			return trialActiveForClients() || currentRoleLevelName === 'client-sharing-user';
		}

	},
	methods: {
		newAccount() {
			return {
				exclusions: [],
				additionalClauses: [],
				additionalClausesToRecitals:[]
			};
		},
		// toggleDiscountRRSPForTax(event){
		// 	if(this.hideForEdit && !this.forceAddNewItem){
		// 		setTimeout(()=> window.location.reload(true), 1500)
		// 	}
		// }
	},
	mounted(){
		for (let i = 0; i < this.agreementData.registeredRetirementSavingsAccounts.length; i++) {
			const rrsp = this.agreementData.registeredRetirementSavingsAccounts[i];
			if (!rrsp.hasOwnProperty('additionalClausesToRecitals')) {
				this.$set(rrsp, 'additionalClausesToRecitals', []);
			}
		}
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Registered Retirement Savings Accounts</h4>

		<list-item
			v-slot="{ item: account, index: index }"
			v-model="accounts"
			:object-prototype="newAccount"
			:testid="'RRSP-'"
			:hideAddBtn=hideForEdit
			:isEditView=hideForEdit
			:itemIndexToShow=itemIndexToShow
			:forceAddNewItem=forceAddNewItem
			:disabled="isViewMode"
		>
		<template v-if="!forceAddNewItem || (forceAddNewItem && index == (accounts.length-1))">
			<input-group
				v-model="account.name"
				class="mb-2"
				input-cols="8"
				label="Name of Financial Institution"
				reset-undefined
				:testid="'RRSP-institution-name-'+index"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="account.lastFourDigits"
				class="my-3"
				reset-undefined
				input-cols="4"
				label="Last four digits of the account"
				placeholder="Digits (Optional)"
				:testid="'RRSP-last-four-digits-'+index"
				:disabled="isViewMode"
			/>

			<radio-group
				v-model="account.ownership"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				label="How is the RRSP owned?"
				reset-undefined
				partner
				select
				:testid="'RRSP-ownership-'+index"
				:length="'long'"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="account.RRSPvalue"
				class="my-3"
				reset-undefined
				dollar
				label="What is the value of the RRSP?"
				:testid="'RRSP-value-'+index"
				:disabled="isViewMode"
			/>
			<template v-if="!clientUserAgreement">
				<yes-no-choice
					v-model="account.discountRRSPForTax"
					reset-undefined
					label="Would you like to discount RRSP for tax? (this will bring the RRSP value into after tax dollars to compare to non-registered assets)"
					class="my-3"
					:testid="'RRSP-discount-for-tax-'+index"
					key="discountRRSPForTax"
					:disabled="isViewMode"
				/>

				<template v-if="account.discountRRSPForTax">
					<label for="taxRate">What tax rate would you like to apply?</label>
					<b-input-group size="sm" append="%" class="row col-6 col-sm-3">
						<b-form-input
							size="sm"
							id="taxRate"
							type="number"
							placeholder="Ex: 30"
							max=3
							step="1"
							v-model="account.taxRateToApplyRRSP"
							:data-testid="'tax-rate-to-apply-'+index"
							key="taxRateToApplyRRSP"
							:disabled="isViewMode"
						/>
					</b-input-group>
				</template>
				<yes-no-choice
					v-model="account.hasExclusions"
					reset-undefined
					label="Are there any exclusions related to the RRSP?"
					class="my-3"
					:testid="'RRSP-has-exclusion-'+index"
					:disabled="isViewMode"
				/>

				<conditional-group class="my-3" :condition="account.hasExclusions">
					<exclusion-table
						v-model="account.exclusions"
						:agreement-data="agreementData"
						:testid="'RRSP-exclusion-'+index"
						:disabled="isViewMode"
					/>
				</conditional-group>

				<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2" v-model="account.additionalClausesToRecitals" :testid="'RRSP-add-clause-to-recitals-'+index+'-'"
					:disabled="isViewMode"/>
				<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="account.additionalClauses" :testid="'RRSP-add-clause-'+index+'-'"
					:disabled="isViewMode"/>
			</template>
			</template>
			</list-item>
			<template v-if="!clientUserAgreement">
			<div class="my-3">
				Divii calculates that an amount of ${{
					formatMoney(overallEqualization)
				}}
				should be transferred from {{ transferredFrom }} to
				{{ transferredTo }} to equalize the values of their shared RRSP
				funds.
			</div>
			<radio-group
				v-model="registeredRetirementSavings.transfer"
				class="mb-2"
				label="What amount of RRSP funds should be transferred?"
				input-cols="8"
				reset-undefined
				select
				:options="[
					{
						text: `$${formatMoney(
							overallEqualization
						)} should be transferred from ${transferredFrom} to ${transferredTo}`,
						value: 'divii-amount',
					},
					{
						text: 'An amount sufficient to equalize the values as of the month end before the date of the transfer',
						value: 'equalize',
					},
					{ text: 'Another amount', value: 'specific-amount' },
					{ text: 'No transfer', value: 'no-transfer' },
				]"
				:testid="'RRSP-transfer'"
				:length="'long'"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="my-3"
				:condition="registeredRetirementSavings.transfer !== 'no-transfer'"
			>
				<input-group
					v-if="
						registeredRetirementSavings.transfer === 'specific-amount'
					"
					v-model="registeredRetirementSavings.transferAmount"
					reset-undefined
					class="my-3"
					dollar
					label="How much should be transferred?"
					:testid="'RRSP-specific-amount'"
					:disabled="isViewMode"
				/>

				<check-group
					v-model="registeredRetirementSavings.transferAccounts"
					class="mb-2"
					input-cols="4"
					label="From what account(s) would you like to transfer?"
					reset-undefined
					:options="accountOptions"
					:testid="'RRSP-transfer-account'"
					:id="'RRSP-transfer-account'"
					:disabled="isViewMode"
				/>
		</conditional-group>
	</template>
	</b-card>
</template>

<script>
import formatMoney from '@/mixins/formatMoney';
export default {
	name: 'SummaryRow',
	mixins: [formatMoney],
	props: {
		label: {
			type: String,
			default: '',
		},
		summary: {
			type: Object,
			default: function () {
				return {
					value: 0,
					spouceOneExclusions: 0,
					spouceTwoExclusions: 0,
					shared: 0,
					spouseOne: 0,
					spouseTwo: 0,
				};
			},
		},
		variant: {
			type: String,
			default: 'primary',
		},
		isAssetView:{
			type: Boolean,
		}
	},
};
</script>

<template>
	<fragment>
		<b-tr>
			<b-td colspan="3" :variant="variant">
				{{ label }}
			</b-td>
			<b-td :variant="variant" class="text-right">
				${{ formatMoney(summary.value) }}
			</b-td>
			<b-td v-if="isAssetView" :variant="variant" class="text-right"></b-td>
			<template v-if="!isAssetView">
			<b-td :variant="variant" class="text-right">
				${{ formatMoney(summary.spouceOneExclusions) }}
			</b-td>
			<b-td :variant="variant" class="text-right">
				${{ formatMoney(summary.spouceTwoExclusions) }}
			</b-td>
			<b-td :variant="variant" class="text-right">
				${{ formatMoney(summary.shared) }}
			</b-td>
			<b-td :variant="variant" class="text-right">
				${{ formatMoney(summary.spouseOne) }}
			</b-td>
			<b-td :variant="variant" class="text-right">
				${{ formatMoney(summary.spouseTwo) }}
			</b-td>
			<b-td :variant="variant" class="text-right"></b-td>
		</template>
		</b-tr>
		<b-tr>
			<b-td :colspan="isAssetView ? '5' : '10'"> </b-td>
		</b-tr>
	</fragment>
</template>
